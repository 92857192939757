import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { OrganizationSource } from '../models/organization-source';
import { Observable } from 'rxjs';
import { OrganizationSourceData } from '../models/organization-source-data';

@Injectable()
export class OrganizationSourceService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Retrieves a list of sources for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - Optional parameters to filter sources.
   * 
   * @returns {Observable<{ data: OrganizationSource[], meta: any }>}
   */
  getAdminOrganizationSources(organizationId: string, params: any = {}): Observable<{ data: OrganizationSource[], meta: any }> {
    return this.apiService.get(`admin/organizations/${organizationId}/sources`, params);
  }

  /**
   * Retrieves the specified source.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} sourceId - The ID of the source.
   * @param {object} params - Optional parameters modify the response.
   * 
   * @returns {Observable<{ data: OrganizationSource, meta: any }>}
   */
  getAdminOrganizationSource(organizationId: string, sourceId: string, params: any = {}): Observable<{ data: OrganizationSource, meta: any }> {
    return this.apiService.get(`admin/organizations/${organizationId}/sources/${sourceId}`, params);
  }

  /**
   * Updates the specified source for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} sourceId - The ID of the source.
   * @param {object} params - The data to update the source.
   * 
   * @returns {Observable<{ data: OrganizationSource, meta: any }>}
   */
  updateAdminOrganizationSource(organizationId: string, sourceId: string, params: any = {}): Observable<{ data: OrganizationSource, meta: any }> {
    return this.apiService.put(`admin/organizations/${organizationId}/sources/${sourceId}`, params);
  }

  /**
   * Creates a new source for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - The data to create the source.
   * 
   * @returns {Observable<{ data: OrganizationSource, meta: any }>}
   */
  createAdminOrganizationSource(organizationId: string, params: any = {}): Observable<{ data: OrganizationSource, meta: any }> {
    return this.apiService.post(`admin/organizations/${organizationId}/sources`, params);
  }

  /**
   * Deletes the specified source for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} sourceId - The ID of the source to be deleted.
   * 
   * @returns {Observable<any>}
   */
  deleteAdminOrganizationSource(organizationId: string, sourceId: string): Observable<any> {
    return this.apiService.delete(`admin/organizations/${organizationId}/sources/${sourceId}`);
  }

  /**
   * Syncs the specified source for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} sourceId - The ID of the source.
   * @param {object} params - The data to update the source.
   * 
   * @returns {Observable<{ data: OrganizationSource, meta: any }>}
   */
  syncAdminOrganizationSource(organizationId: string, sourceId: string, params: any = {}): Observable<{ data: OrganizationSource, meta: any }> {
    return this.apiService.post(`admin/organizations/${organizationId}/sources/${sourceId}/sync`, params);
  }

  /**
   * Syncs the specified source for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} sourceId - The ID of the source.
   * @param {object} params - The data to update the source.
   * 
   * @returns {Observable<{ data: OrganizationSource, meta: any }>}
   */
  scanAdminOrganizationSource(organizationId: string, sourceId: string, params: any = {}): Observable<{ data: OrganizationSource, meta: any }> {
    return this.apiService.post(`admin/organizations/${organizationId}/sources/${sourceId}/scan`, params);
  }

  /**
   * Retrieves a list of sources for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - Optional parameters to filter sources.
   * 
   * @returns {Observable<{ data: OrganizationSource[], meta: any }>}
   */
  getPublicOrganizationSources(organizationId: string, params: any = {}): Observable<{ data: OrganizationSource[], meta: any }> {
    return this.apiService.get(`public/organizations/${organizationId}/sources`, params);
  }

}
