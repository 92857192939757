import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Source } from '../models/source';

@Injectable()
export class SourceService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Retrieves a list of sources.
   * 
   * @param {any} [params] - Optional parameters to filter sources
   * 
   * @returns {Observable<{ data: Source[], meta: any }>}
   */
  getPublicSources(params: any = {}): Observable<{ data: Source[], meta: any }> {
    return this.apiService
      .get(`public/sources`, params);
  }

}
