import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/user';
import { RiterzCoreConfiguration } from '../riterz-core.configuration';
import { CookieService } from './cookie.service';

@Injectable()
export class UserService {
  private currentUserSubject = new BehaviorSubject<User | null>(null);
  public currentUser = this.currentUserSubject.asObservable();

  constructor(
    private readonly configuration: RiterzCoreConfiguration,
    private cookieService: CookieService,
    private apiService: ApiService
  ) { }


  getToken() {
    return this.cookieService.get(this.configuration.accessTokenName);
  }

  getCurrentUser(params: any = {}) {
    const token = this.cookieService.get(this.configuration.accessTokenName);
    if (token) {
      if (token === 'undefined') {
        this.cookieService.delete(this.configuration.accessTokenName);
        return of(false);
      }

      return this.apiService
        .get(`users/me`, params)
        .pipe(
          map(response => {
            this.currentUserSubject.next(response.data);
            return response;
          })
        );
    } else {
      return of(false);
    }
  }

  updateCurrentUser(params = {}) {
    if (this.cookieService.get(this.configuration.accessTokenName)) {
      return this.apiService
        .put(`users/me`, params);
    } else {
      return of(false);
    }
  }

  deleteCurrentUser(params: any = {}) {
    if (this.cookieService.get(this.configuration.accessTokenName)) {
      return this.apiService
        .delete(`users/me`, params)
        .pipe(
          map(response => {
            this.logout().subscribe();
            return response;
          })
        );
    } else {
      return of(false);
    }
  }

  deleteCurrentUserAvatar(params: any = {}) {
    if (this.cookieService.get(this.configuration.accessTokenName)) {
      return this.apiService
        .delete(`users/me/avatar`, params);
    } else {
      return of(false);
    }
  }

  register(params: any) {
    return this.apiService.post(`users/register`, params)
      .pipe(
        map(
          (response) => {
            this.cookieService.set(this.configuration.accessTokenName, response.access_token, 14, '/');
            this.cookieService.set(this.configuration.refreshTokenName, response.refresh_token, 14, '/');
            return response;
          }
        )
      );
  }

  login(params: any) {
    return this.apiService.post(`users/login`, params)
      .pipe(
        map(
          (response) => {
            this.cookieService.set(this.configuration.accessTokenName, response.access_token, 14, '/');
            this.cookieService.set(this.configuration.refreshTokenName, response.refresh_token, 14, '/');
            return response;
          }
        )
      );
  }

  loginWithCode(code: string, params = {}) {
    return this.apiService.get(`users/email-code-login/${code}`, params)
      .pipe(
        map(
          (response) => {
            this.cookieService.set(this.configuration.accessTokenName, response.access_token, 14, '/');
            this.cookieService.set(this.configuration.refreshTokenName, response.refresh_token, 14, '/');
            return response;
          }
        )
      );
  }

  loginWithCodeRequest(params = {}) {
    return this.apiService.post(`users/email-code-login`, params)
      .pipe(
        map(
          (response) => {
            this.cookieService.set(this.configuration.accessTokenName, response.access_token, 14, '/');
            return response;
          }
        )
      );
  }

  setRiterzTokens(refreshToken: string, accessToken: string) {
    this.cookieService.set(this.configuration.accessTokenName, accessToken, 14, '/');
    this.cookieService.set(this.configuration.refreshTokenName, refreshToken, 14, '/');
    return;
  }

  refreshToken() {
    const refreshToken = this.cookieService.get(this.configuration.refreshTokenName);
    const headers = { 'refresh-token': refreshToken };
    const params = { client_id: this.configuration.clientId }
    return this.apiService.post(`users/refresh`, params, headers)
      .pipe(
        map(
          (response) => {
            this.cookieService.set(this.configuration.accessTokenName, response.access_token, 14, '/');
            this.cookieService.set(this.configuration.refreshTokenName, response.refresh_token, 14, '/');
            return response;
          }
        )
      );
  }

  logout() {
    if (this.cookieService.get(this.configuration.accessTokenName)) {
      return this.apiService
        .get(`users/logout`)
        .pipe(map(response => {
          this.cookieService.delete(this.configuration.accessTokenName, '/');
          this.cookieService.delete(this.configuration.refreshTokenName, '/');
          this.currentUserSubject.next(null);
          return response.data;
        }));
    } else {
      return of(false);
    }
  }

  verifyEmail(id: string, token: string) {
    return this.apiService.get(`users/email/verify/${id}/${token}`);
  }

  getUsers(params = {}) {
    return this.apiService.get(`admin/users`, params);
  }

  getUser(userId: string, params = {}) {
    return this.apiService.get(`admin/users/${userId}`, params);
  }

  updateUser(userId: string, params = {}) {
    return this.apiService.put(`admin/users/${userId}`, params);
  }

  createUser(params = {}) {
    return this.apiService.post(`admin/users`, params);
  }

  deleteUser(userId: string) {
    return this.apiService.delete(`admin/users/${userId}`);
  }

  getOrganizationUsers(organizationId: string, params = {}) {
    return this.apiService.get(`organizations/${organizationId}/users`, params);
  }

  getOrganizationUser(organizationId: string, userId: string, params = {}) {
    return this.apiService.get(`organizations/${organizationId}/users/${userId}`, params);
  }

  updateOrganizationUser(organizationId: string, userId: string, params = {}) {
    return this.apiService.put(`organizations/${organizationId}/users/${userId}`, params);
  }

  createOrganizationUser(organizationId: string, params = {}) {
    return this.apiService.post(`organizations/${organizationId}/users`, params);
  }

  deleteOrganizationUser(organizationId: string, userId: string) {
    return this.apiService.delete(`organizations/${organizationId}/users/${userId}`);
  }

  uploadUserAvatar(params: any = {}) {
    return this.apiService.upload(`users/me/avatar`, params);
  }

  deleteUserAvatar(params: any = {}) {
    return this.apiService.delete(`users/me/avatar`, params);
  }

}
