import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { OrganizationSourceData } from '../models/organization-source-data';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationSourceDataService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Retrieves a list of sources for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - Optional parameters to filter sources.
   * 
   * @returns {Observable<{ data: OrganizationSource[], meta: any }>}
   */
  getAdminOrganizationData(organizationId: string, params: any = {}): Observable<{ data: OrganizationSourceData[], meta: any }> {
    return this.apiService.get(`admin/organizations/${organizationId}/source-data`, params);
  }

  /**
   * Retrieves a list of data for the specified organization source.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} organizationSourceId - The ID of the organization source.
   * @param {object} params - Optional parameters to filter sources.
   * 
   * @returns {Observable<{ data: OrganizationSourceData[], meta: any }>}
   */
  getAdminOrganizationSourceData(organizationId: string, organizationSourceId: string, params: any = {}): Observable<{ data: OrganizationSourceData[], meta: any }> {
    return this.apiService.get(`admin/organizations/${organizationId}/sources/${organizationSourceId}/data`, params);
  }

  /**
   * Retrieves a datum for the specified organization source.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} organizationSourceId - The ID of the organization source.
   * @param {string} organizationSourceDataId - The ID of the organization source data.
   * @param {object} params - Optional parameters to filter sources.
   * 
   * @returns {Observable<{ data: OrganizationSourceData[], meta: any }>}
   */
  getAdminOrganizationSourceDatum(organizationId: string, organizationSourceId: string, organizationSourceDataId: string, params: any = {}): Observable<{ data: OrganizationSourceData, meta: any }> {
    return this.apiService.get(`admin/organizations/${organizationId}/sources/${organizationSourceId}/data/${organizationSourceDataId}`, params);
  }
  
  /**
   * Updates the datum for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} organizationSourceId - The ID of the organization source.
   * @param {string} organizationSourceDataId - The ID of the organization source data.
   * @param {object} params - The data to update the source.
   * 
   * @returns {Observable<{ data: OrganizationSourceData, meta: any }>}
   */
  updateAdminOrganizationSourceDatum(organizationId: string, organizationSourceId: string, organizationSourceDataId: string, params: any = {}): Observable<{ data: OrganizationSourceData, meta: any }> {
    return this.apiService.put(`admin/organizations/${organizationId}/sources/${organizationSourceId}/data/${organizationSourceDataId}`, params);
  }

  /**
   * Retrieves a datum for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} organizationSourceId - The ID of the organization source.
   * @param {string} organizationSourceDataId - The ID of the organization source data.
   * @param {object} params - Optional parameters to filter sources.
   * 
   * @returns {Observable<{ data: OrganizationSourceData[], meta: any }>}
   */
  deleteAdminOrganizationSourceDatum(organizationId: string, organizationSourceId: string, organizationSourceDataId: string, params: any = {}): Observable<{ data: OrganizationSourceData[], meta: any }> {
    return this.apiService.delete(`admin/organizations/${organizationId}/sources/${organizationSourceId}/data/${organizationSourceDataId}`, params);
  }

  /**
   * Retrieves a list of sources for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - Optional parameters to filter sources.
   * 
   * @returns {Observable<{ data: OrganizationSource[], meta: any }>}
   */
  getPublicOrganizationData(organizationId: string, params: any = {}): Observable<{ data: OrganizationSourceData[], meta: any }> {
    return this.apiService.get(`public/organizations/${organizationId}/source-data`, params);
  }

}
