import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { OrganizationOfferGroup } from '../models/organization-offer-group';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationOfferGroupService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Retrieves a list of offers for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - Optional parameters to filter offers.
   * 
   * @returns {Observable<{ data: OrganizationOfferGroup[], meta: any }>}
   */
  getOrganizationOfferGroups(organizationId: string, params: any = {}): Observable<{ data: OrganizationOfferGroup[], meta: any }> {
    return this.apiService.get(`organizations/${organizationId}/offer-groups`, params);
  }

  /**
   * Retrieves the specified offer group.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} offerGroupId - The ID of the offer.
   * @param {object} params - Optional parameters modify the response.
   * 
   * @returns {Observable<{ data: OrganizationOfferGroup, meta: any }>}
   */
  getOrganizationOfferGroup(organizationId: string, offerGroupId: string, params: any = {}): Observable<{ data: OrganizationOfferGroup, meta: any }> {
    return this.apiService.get(`organizations/${organizationId}/offer-groups/${offerGroupId}`, params);
  }

  /**
   * Updates the specified offer group for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} offerGroupId - The ID of the offer.
   * @param {object} params - The data to update the offer.
   * 
   * @returns {Observable<{ data: OrganizationOfferGroup, meta: any }>}
   */
  updateOrganizationOfferGroup(organizationId: string, offerGroupId: string, params: any = {}): Observable<{ data: OrganizationOfferGroup, meta: any }> {
    return this.apiService.put(`organizations/${organizationId}/offer-groups/${offerGroupId}`, params);
  }

  /**
   * Creates a new offer group for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - The data to create the offer.
   * 
   * @returns {Observable<{ data: OrganizationOfferGroup, meta: any }>}
   */
  createOrganizationOfferGroup(organizationId: string, params: any = {}): Observable<{ data: OrganizationOfferGroup, meta: any }> {
    return this.apiService.post(`organizations/${organizationId}/offer-groups`, params);
  }

  /**
   * Deletes the specified offer group for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} offerGroupId - The ID of the offer to be deleted.
   * 
   * @returns {Observable<any>}
   */
  deleteOrganizationOfferGroup(organizationId: string, offerGroupId: string): Observable<any> {
    return this.apiService.delete(`organizations/${organizationId}/offer-groups/${offerGroupId}`);
  }

  /**
   * Updates the specified offer group for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} offerGroupId - The ID of the offer.
   * @param {object} params - The data to update the offer.
   * 
   * @returns {Observable<{ data: OrganizationOfferGroup, meta: any }>}
   */
  updateOrganizationOfferGroupSequence(organizationId: string, offerGroupId: string, params: any = {}): Observable<{ data: OrganizationOfferGroup, meta: any }> {
    return this.apiService.put(`organizations/${organizationId}/offer-groups/${offerGroupId}/change-sequence`, params);
  }

  /**
   * Retrieves a list of offer groups for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - Optional parameters to filter offers.
   * 
   * @returns {Observable<{ data: OrganizationOfferGroup[], meta: any }>}
   */
  getPublicOrganizationOfferGroups(organizationId: string, params: any = {}): Observable<{ data: OrganizationOfferGroup[], meta: any }> {
    return this.apiService.get(`public/organizations/${organizationId}/offer-groups`, params);
  }

}
